$(document).on('turbolinks:load', function() {
  (function($) {
    'use strict';

    var body = $("body") , windowWidth = window.innerWidth;

    /**
     * @description Initialize Bootstrap tooltip
     * @param {(Element|jQuery)} [context] - A DOM Element, Document, or jQuery to use as context.
     * @requires bootstrap.js, Popper.js
     */
    $('[data-toggle="tooltip"]').tooltip();

    /**
     * @description card Options for fullscreen , close and refresh
     * @param {(Element|jQuery)} [context] - A DOM Element, Document, or jQuery to use as context.
     * @requires jQuery
     */
    $(document).on("click", ".js-card-fullscreen", function (e) {
      e.preventDefault();
      $(this).closest('.card').toggleClass('is-fullscreen');
    });
    $(document).on("click", ".js-card-close", function (e) {
      e.preventDefault();
      $(this).closest('.card').remove();
    });
    $(document).on("click", ".js-card-refresh", function (e) {
      e.preventDefault();
      $(this).closest('.card').append("<div class='loading-container'></div> ");
      //write your code here

      //once done remove the loading
    });

    /**
     * @description Override Default Behaviour for scroll
     * @param {(Element|jQuery)} [context] - A DOM Element, Document, or jQuery to use as context.
     * @requires jquery.bootstrap
     */
    $('.modal').on('show.bs.modal', function (e) {
      if($(e.currentTarget).attr("data-popup")){
        body.addClass("body-scrollable");
      }
    });

    $('.modal').on('hidden.bs.modal', function (e) {
      body.removeClass("body-scrollable");
    });

    /**
     * @description Initialize custom scrollbar
     * @param {(Element|jQuery)} [context] - A DOM Element, Document, or jQuery to use as context.
     * @requires jquery.scrollbar plugin
     */
    $(".js-scrollbar, .card-scroll").scrollbar();

    /**
     * @description Initialize bootstrap datepicker
     * @param {(Element|jQuery)} [context] - A DOM Element, input tag  to use as context.
     * @requires bootstrap datepicker plugin by uxsolutions
     */
    var jqueryUiOpts = {
      dateFormat: 'yy-mm-dd',
      showOtherMonths: true,
      selectOtherMonths: true
    }

    $(document).on("focus", ".js-datepicker", function (e) {
      $(this).datepicker(jqueryUiOpts)
    });

    $(".js-datepicker").datepicker(jqueryUiOpts);

    $(document).on("focus", ".inline-datepicker", function (e) {
      $(this).datepicker(jqueryUiOpts)
    });
    $(document).on("change", ".inline-datepicker", function (e) {
      $('.inline-button').click()
    });


    $('.map-datepicker').datepicker().on('change', function (e) {
      $('#map-form').submit();
    });

    /**
     * @description Initialize daterangepicker
     * @param {(Element|jQuery)} [context] - A DOM Element, input tag  to use as context.
     * @requires daterangepicker plugin by dangrossman
     * https://www.daterangepicker.com/
     */
    $('.input-daterange').daterangepicker({
      autoUpdateInput: false,
      locale: {
          cancelLabel: 'Clear'
      }
    });

    $('input.input-daterange').on('apply.daterangepicker', function(ev, picker) {
      $(this).val(picker.startDate.format('MM/DD/YYYY') + ' - ' + picker.endDate.format('MM/DD/YYYY'));
    });

    $('input.input-daterange').on('cancel.daterangepicker', function(ev, picker) {
      $(this).val('');
    });

    /**
     * @description Initialize select2
     * @param {(Element|jQuery)} [context] - A DOM Element, input tag  to use as context.
     * @requires select2 plugin
     */
    $('.star-rating').rating(
      {
        theme: "krajee-svg",
        displayOnly: true,
        showCaption: false,
        size: 'sm'
      }
    );
    /**
     * @description floating label transformations - making the label visible when input is provided
     * @param {(Element|jQuery)} [context] - A Form input or select to use as context.
     * @requires jQuery
     */
    $(document).on('input', '.floating-label input', function (e) {
      var  item =$(this).parents('.floating-label');
      $(this).val() ? item.addClass('show-label') : item.removeClass('show-label');
    });

    $(document).on('blur', '.floating-label input', function (e) {
      var  item =$(this).parents('.floating-label');
      $(this).val() ? item.addClass('show-label') : item.removeClass('show-label');
    });

    //checking for pre-filled forms
    $(".floating-label input").each(function () {
      var  item =$(this).parents('.floating-label');
      $(this).val() ? item.addClass('show-label') : item.removeClass('show-label');
    });

    // phone number input mask
    $('.phone-mask').inputmask("999-999-9999");

    /**
     * @description Initialize listjs - searchable list
     * @param (DOM Element), values as DOM Selector.
     * @requires listjs plugin
     */
    //creates searchable list for site search modal
    new List('site-search', {
      valueNames: [
        'name',
      ]
    });

    //creates searchable list for contacts page
    new List('contact-search', {
      valueNames: [
        'searchBy-name',
      ]
    });

    //create a trigger for removing the loading after 500 ms
    $(document).on("click", ".js-card-refresh", function (e) {
      hideLoading();
    });
    /**
     * @function hideLoading
     * @description hideLoading for cards after timeout
     */
    function hideLoading() {
      var containers = $(".loading-container");
      setTimeout(function () {
        containers.parents('.card').removeClass("is-loading");
        containers.fadeOut(500, function () {
          containers.remove();

        });
      }, 1000)
    }

  })(window.jQuery);
})
