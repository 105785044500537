$(document).ready(function() {
  (function($) {
    'use strict';

    /**
     * @property colors
     * @description SET colors for charts includes system colors and pastel colors for charts
     * @returns {array} - array of colors/colours
     */
    window.colors = [
      "#687ae8",
      "#12bfbb",
      "#ffb058",
      "#2991cf",
      "#87b8d4",
      "#109693",
      "#f29494",
      "#527cf9",
      "#7140d1",
      "#e79e4e",
      "#52b4ee",
      "#6ed7e0",
      "#8fa6b4",
      "#ffcfcf",
      "#28304e",
      "#95aac9",
      "#f2545b",
      "#f7bc06",
      "#00cc99",
      "#19b5fe",
      "#E3EBF6"
    ];
  })(window.jQuery);

  /**
   * @plugin alertNotify
   * @param {string | html} message - message text or html
   * @param {string} alert_type - primary,danger,info,warning,default,light,dark
   * @param {boolean} dismiss - close button
   * @description to pin  alert on top of page
   */
  (function ( $ ) {

    $.fn.alertNotify = function( options ) {

      // Default options
      var settings = $.extend({
        message: 'Alert Message',
        type: 'primary',
        dismiss: true
      }, options );
      var dismissBtn =   '<button type="button" class="close" data-dismiss="alert" aria-label="Close"> ' +
        '<span aria-hidden="true">&times;</span> </button> ';
      // Apply options
      if(this.find(".alert-container").length === 0){
        this.append("<div class='alert-container'></div>");
      }
      if(!settings.dismiss){
        dismissBtn = '';
      }
      this.find(".alert-container").append('<div class="alert alert-'+settings.type+' alert-dismissible fade show" role="alert">' +
        settings.message + dismissBtn +
        '</div>' );

    };

  }( jQuery ));

})
