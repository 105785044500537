$(document).on('turbolinks:load cocoon:after-insert', function () {
  (function ($) {
    'use strict';
    $('.js-select2').select2();
    $('.js-select2-tag').select2({tags: true});
    $('#service_subscription_recurring_plan_id, #service_subscriptions_recurring_plan_id').on('select2:select', function () {
      let change = new Event('change')
      $(this)[0].dispatchEvent(change)
    })

  })(window.jQuery);
})
