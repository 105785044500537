import { Controller } from "stimulus";
import { Loader } from "@googlemaps/js-api-loader";
import "jquery";

export default class extends Controller {
  static values = {
    googleMapKey: String
  }
  static targets = [
    "addNewAddressBtn",
    "customerType",
    "firstNameFieldLabel",
    "lastNameDiv",
    "lastNameField",
    "invoiceEmail"
  ];

  // check type and render fields accordingly on initial load
  connect() {
    // map api loader
    const loader = new Loader({
      apiKey: this.googleMapKeyValue,
      version: "weekly",
      libraries: ["places"],
    });
    // callback
    loader.loadCallback((e) => {
      this.connectGooglePlacesApi();
    });

    this.addAddressCallback();

    this.typeChange();
  }

  // triggered when customer type is changed by the user
  typeChange() {
    if (this.customerTypeTarget.value === "individual") {
      this.lastNameFieldTarget.setAttribute("required", true);
      this.lastNameDivTarget.removeAttribute("hidden");
      // manually inserting the abbr tag as it gets removed upon label text change (it inserts styled star symbol for required field)
      this.firstNameFieldLabelTarget.innerHTML =
        'First Name <abbr title="required">*</abbr>';
      // handling the value of last name to original value of input field incase if the type was accidentally changed but not submitted
      this.lastNameFieldTarget.value =
        this.lastNameFieldTarget.getAttribute("data-prev-val");
    } else if (this.customerTypeTarget.value === "company") {
      this.lastNameFieldTarget.removeAttribute("required");
      this.lastNameDivTarget.setAttribute("hidden", true);
      // manually inserting the abbr tag as it gets removed upon label text change (it inserts styled star symbol for required field)
      this.firstNameFieldLabelTarget.innerHTML =
        'Company Name <abbr title="required">*</abbr>';
      // empty last name if company is selected
      this.lastNameFieldTarget.value = "";
    }
  }

  addAddressCallback() {
    // appending google api to new field when new form section is appended
    const newAddressBtn = document.querySelector(".add_service_address_link");
    newAddressBtn.addEventListener("click", () => {
      setTimeout(() => {
        this.connectGooglePlacesApi();
      }, 300);
    });
  }

  // this google api places function will automatically append api functionality to all input fields with class 'customer-address-autocomplete'
  // upon selection of a value from api result it will attempt to fill address from by searching classes and subsequent fields inside them
  // parent div of input fields need to have following classes for detection, more can added as conditional
  // (customer-address-field-complete, customer-address-field-city, customer-address-field-state, customer-address-field-zip)
  connectGooglePlacesApi() {
    var inputs = document.getElementsByClassName(
      "customer-address-autocomplete"
    );
    // places api options
    const options = {
      // countries restrictions.
      componentRestrictions: { country: "us" },
      // retrieved data restriction.
      fields: ["address_components"],
      // this option restricts the bounds/area/circle from which the result will shows, if true then used with 'bounds' option.
      strictBounds: false,
      // this option restrict result type example: establishments, localities, markets, etc.
      // types: ["locality"],
    };

    // multiple form section handling as user can add multiple addresses
    for (var i = 0; i < inputs.length; i++) {
      let currentInputField = inputs[i];
      let autocomplete = new google.maps.places.Autocomplete(
        currentInputField,
        options
      );
      // form fill handling after selecting an address
      autocomplete.addListener("place_changed", function () {
        let streetAddress = "";
        let postalCode = "";
        let state = "";
        let stateShort = "";
        let city = "";
        let parentDiv = currentInputField.parentElement.parentElement;
        let places = autocomplete.getPlace();
        let streetField = parentDiv.querySelector(
          ".customer-address-field-complete input"
        );
        let cityField = parentDiv.querySelector(
          ".customer-address-field-city input"
        );
        let stateFieldSelect2 = $(
          parentDiv.querySelector(".customer-address-field-state select")
        );
        let stateFieldText = parentDiv.querySelector(
          ".customer-address-field-state .select2-selection__rendered"
        );
        let postalField = parentDiv.querySelector(
          ".customer-address-field-zip input"
        );

        // extract data from places api selected value
        for (const component of places.address_components) {
          // @ts-ignore remove once typings fixed
          const componentType = component.types[0];

          switch (componentType) {
            case "street_number": {
              streetAddress = component.long_name;
              break;
            }

            case "route": {
              streetAddress = `${streetAddress} ${component.long_name}`;
              break;
            }

            case "postal_code": {
              postalCode = component.long_name;
              break;
            }

            case "locality":
              city = component.long_name;
              break;

            case "administrative_area_level_1": {
              stateShort = component.short_name;
              state = `${component.long_name} (${component.short_name})`;
              break;
            }
          }
        }
        // fill data in the form section of the places api selector
        if (streetField) {
          streetField.value = streetAddress;
        }

        if (cityField) {
          cityField.value = city;
        }

        // select2 field data insertion handling
        if (stateFieldSelect2 && stateFieldText) {
          stateFieldSelect2.val(stateShort);
          stateFieldText.innerText = state;
        }

        if (postalField) {
          postalField.value = postalCode;
        }
      });
    }
  }
 
  handleInvoiceEmailToggle( event ) {
    const target = $(event.target);
    const isChecked = target.is(":checked");

    if (isChecked) {
      this.invoiceEmailTarget.removeAttribute("disabled");
    } else {
      this.invoiceEmailTarget.setAttribute('disabled', 'disabled');
    }
  }

  copyToAnotherTarget( event ) {
    const target = $(event.target);
    const currentInputValue = target.val();
    const copyTarget = $(event.currentTarget.dataset.copyTarget);
    if (copyTarget.val() == "") {
      copyTarget.val(currentInputValue);
    }
  }

}
