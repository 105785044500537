import { faPerson, faCheckCircle } from "@fortawesome/free-solid-svg-icons";

export function mapLoader() {
  let map_element = document.getElementById("dynamic-map");
  if (map_element) {
    let locations = JSON.parse(
      document.getElementById("dynamic-map").dataset.locations
    );
    let technicians_data =
      document.getElementById("dynamic-map").dataset.technicians;

    let myStyles = [
      {
        featureType: "poi",
        elementType: "labels",
        stylers: [{ visibility: "off" }],
      },
    ];
    let map = new google.maps.Map(document.getElementById("dynamic-map"), {
      center: parseCoordinates(locations[0]),
      zoom: 12.1,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      styles: myStyles,
    });

    if (locations.length > 1) {
      let bounds = generateBounds(locations);
      map.fitBounds(bounds);
    }

    const infoWindow = new google.maps.InfoWindow({
      content: "",
      disableAutoPan: true,
    });

    if (technicians_data) {
      let technicians = JSON.parse(technicians_data);
      if (technicians.length > 0) {
        technicians.map((technician) => {
          const label =
            '<div id="content">' +
            '<div id="siteNotice">' +
            "</div>" +
            `<h5 id="firstHeading" class="firstHeading">${technician.name}</h1>` +
            "</div>";
          const position = parseCoordinates(technician);
          let svgTechnicianMarker = {
            path: String(faPerson.icon[4]),
            fillColor: technician.color,
            fillOpacity: 1,
            strokeWeight: 1,
            strokeColor: "#ffffff",
            scale: 0.075,
            anchor: new google.maps.Point(
              faPerson.icon[0] / 2,
              faPerson.icon[1]
            ),
          };
          const marker = new google.maps.Marker({
            position,
            map,
            icon: svgTechnicianMarker,
          });

          marker.addListener("click", () => {
            infoWindow.setContent(label);
            infoWindow.open(map, marker);
          });
        });
      }
    }

    // Add some markers to the map.
    locations.map((location) => {
      const label =
        '<div id="content">' +
        '<div id="siteNotice">' +
        "</div>" +
        `<h5 id="firstHeading" class="firstHeading">${location.title}</h1>` +
        '<div id="bodyContent">' +
        `${location.phone_number} </br>` +
        `<a href="/jobs/${location.job_id}" target="_blank">${location.job_name}</a> </br>` +
        "<br/>" +
        `${location.address.street} </br>` +
        `${location.address.city}, ${location.address.state} ${location.address.zip} </br>` +
        "</div>" +
        "</div>";
      const position = parseCoordinates(location);
      const marker = new google.maps.Marker({
        position,
        map,
        icon: getIcon(location),
      });

      marker.addListener("click", () => {
        infoWindow.setContent(label);
        infoWindow.open(map, marker);
      });
      return marker;
    });
  }

  function getIcon(location) {
    let svgMarker = {
      path: "M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z",
      fillColor: location.color,
      fillOpacity: 1,
      strokeWeight: 0,
      rotation: 0,
      scale: 1.8,
      anchor: new google.maps.Point(12, 24),
    };
    let svgCompletedMarker = {
      path: String(faCheckCircle.icon[4]),
      fillColor: "#04E538",
      fillOpacity: 1,
      strokeWeight: 1,
      strokeColor: "#ffffff",
      scale: 0.055,
      anchor: new google.maps.Point(
        faCheckCircle.icon[0] / 2,
        faCheckCircle.icon[1]
      ),
    };
    if (location.hasOwnProperty("completed") && location.completed) {
      return svgCompletedMarker;
    } else if (location.color.length == 0) {
      return null;
    } else {
      return svgMarker;
    }
  }

  function generateBounds(locations) {
    let latlngList = [];
    locations.forEach((location) => {
      latlngList.push(
        new google.maps.LatLng(
          parseFloat(location.latitude),
          parseFloat(location.longitude)
        )
      );
    });

    let bounds = new google.maps.LatLngBounds();
    latlngList.forEach(function (n) {
      bounds.extend(n);
    });
    return bounds;
  }

  function parseCoordinates(location) {
    return {
      lat: parseFloat(location.latitude),
      lng: parseFloat(location.longitude),
    };
  }
}
