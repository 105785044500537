import { Controller } from "stimulus"
import "jquery"

export default class extends Controller {
  //"data-action": "click->dashboard-maps#reloadLocations"
  // $(document).on("click", " .admin-close-sidebar ,.sidebar-backdrop"
  handleClick( event ) {
    event.preventDefault();
    $('body').toggleClass("sidebar-pinned");
    $(event.currentTarget).toggleClass("pinned");
    // trigger resize event for charts to redraw if required
    window.dispatchEvent(new Event('resize'));
  }

  mouseOver( event ) {
    if (!$('.sidebar-toggle').is(":visible") && $('body:not(.sidebar-pinned)').length > 0) {
      $(event.currentTarget).addClass("sidebar-show");
    }
  }

  mouseLeave( event ) {
    if (!$('.sidebar-toggle').is(":visible") && $('body:not(.sidebar-pinned)').length > 0) {
      $(event.currentTarget).removeClass("sidebar-show");
    }
  }

}
