$(document).on('turbolinks:load', function() {
  (function($) {
    'use strict';

    $('.js-bootstrap-dropdown-filter').on('hide.bs.dropdown', function (e) {
      if (e.clickEvent) {
        e.preventDefault();
      }
    })

    $('.js-bootstrap-dropdown-filter form').on('reset', function (e) {
      resetInput($('.dropdown-item input', this))
      resetSelect($('.dropdown-item select', this))

      $('.filter-field', this).addClass('d-none')
    })

    $('.js-bootstrap-dropdown-filter input[type="checkbox"]').change(function () {
      const form = $(this).parents('form')
      const target = $(this).data('target')
      $(target, form).parents('.dropdown-item').toggleClass('d-none')

      const checked = $(this).prop('checked')

      if (!checked) {
        resetInput($(target, form))
        resetSelect($(target, form))
      }
    })

    function resetInput($input) {
      // Reset radio/checkbox
      $input.removeAttr('checked')
      $input.removeAttr('value')

      // Reset input field
      $input.val('')
    }

    function resetSelect($select) {
      // Reset select
      $select.prop('selectedIndex', 0)
      $('option', $select).removeAttr('selected')
    }
  }(window.jQuery))
})
