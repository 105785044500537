import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [
    'individualCheckboxBtn', 'selectAllCheckboxBtn'
  ]

  collectionSelectionHandler(e){
    this.individualCheckboxBtnTargets.forEach((rowCheckbox) => {
      rowCheckbox.checked = e.target.checked
    })
    if (e.target.checked) {
      $("#selected_ids").val("all")
    } else {
      $("#selected_ids").val("")
    }
  }

  individualSelectionHandler(e){
    if(!e.target.checked){
      this.selectAllCheckboxBtnTarget.checked = false
    }
    this.updateSelectedToBatchActionForm()
  }

  updateSelectedToBatchActionForm(){
    let selected_ids = []
    this.individualCheckboxBtnTargets.forEach((rowCheckbox) => {
      if (rowCheckbox.checked) {
        selected_ids.push(rowCheckbox.value)
      }
    })
    $("#selected_ids").val(selected_ids)    
  }  
}