// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
import Turbolinks from "turbolinks";
import * as ActiveStorage from "@rails/activestorage";
import "channels";
import { Application } from "stimulus";
import { Autocomplete } from "stimulus-autocomplete";
import { definitionsFromContext } from "stimulus/webpack-helpers";
import Sortable from "stimulus-sortable";
import ApexCharts from "apexcharts";

require("jquery");

import "popper.js";
import "bootstrap"; //v4.6.1
import "select2";
import "select2/dist/css/select2.css";
import "jquery.scrollbar";
import "jquery.scrollbar/jquery.scrollbar.css";
import "inputmask/dist/jquery.inputmask";
import "daterangepicker";
import "daterangepicker/daterangepicker.css";
import "bootstrap-datepicker";
import "bootstrap-datepicker/dist/css/bootstrap-datepicker.css";
import "bootstrap-notify";
import Flatpickr from 'stimulus-flatpickr';
import "list.js";
import "moment";
import "bootstrap-notify";
import "@nathanvda/cocoon";
import "bootstrap-star-rating";
import "bootstrap-star-rating/themes/krajee-svg/theme.js";

require("webpack-jquery-ui");
require("webpack-jquery-ui/css");
require("custom/atmos-initializer");
require("custom/atmos-listeners");
require("custom/delete-image");
require("custom/bootstrap-dropdown-filter");
require("custom/customer-service-address-select");
require("custom/ice-cube-scheduler");
require("@googlemaps/js-api-loader");
require("custom/service-items-handler");
require("custom/select-2-loader");
require("custom/mobiscroll");
require("flatpickr/dist/flatpickr.css");

Rails.start();
Turbolinks.start();
ActiveStorage.start();
window.Rails = Rails;
window.ApexCharts = ApexCharts;
const application = Application.start();

class ProvaletAutocomplete extends Autocomplete {

  onEnterKeydown = (event) => {
    let selected = this.selectedOption;
    if (!selected && this.resultsShown) {
      selected = this.sibling(true);
    }
    if (selected && this.resultsShown) {
      this.commit(selected)
      if (!this.hasSubmitOnEnterValue) {
        event.preventDefault()
      }
    }
  }

  commit(selected) {
    if (selected.getAttribute("aria-disabled") === "true") return;

    if (selected.firstChild instanceof HTMLAnchorElement) {
      selected.firstChild.click();
      this.close();
      return;
    }

    var textValue = selected.getAttribute("data-autocomplete-label") || selected.textContent.trim();
    var value = selected.getAttribute("data-autocomplete-value") || textValue;
    this.inputTarget.value = textValue;

    if (this.hasHiddenTarget) {
      this.hiddenTarget.value = value;
      this.hiddenTarget.dispatchEvent(new Event("input"));
      this.hiddenTarget.dispatchEvent(new Event("change"));
    } else {
      this.inputTarget.value = value;
    }

    this.inputTarget.focus();
    this.hideAndRemoveOptions();
    this.element.dispatchEvent(new CustomEvent("autocomplete.change", {
      bubbles: true,
      detail: {
        value: value,
        textValue: textValue,
        selected: selected
      }
    }));
  }
}
application.register("autocomplete", ProvaletAutocomplete);
application.register("sortable", Sortable);
application.register('flatpickr', Flatpickr);
const context = require.context("../controllers", true, /\.js$/);
application.load(definitionsFromContext(context));
