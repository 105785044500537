import { Controller } from "stimulus"
import "jquery"

export default class extends Controller {
  //"data-action": "click->dashboard-maps#reloadLocations"
  handleDropdown( event ) {
    event.preventDefault();
    var target = jQuery(event.currentTarget);
    if (!target.next().is(":visible")) {
      //opens the adjacent list to the target
      target.next().slideDown();
      target.parent().addClass("opened");
    } else {
      //closes the adjacent list to the target
      target.next().slideUp();
      target.parent().removeClass("opened");
    }
  }
}
