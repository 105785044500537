import { Controller } from 'stimulus'
import 'jquery'

/*

TODO to get this working:

1. ✅✅✅ Build correct nested attributes input name attributes -> estimate_line_items_attributes_0_name
2. ✅✅✅ Submit and verify it saved everything!
3. ✅✅✅ Display autocomplete options correctly.

4. ✅✅✅ Clicking on an autocomplete result populates a row with the name, price, and hidden field for source_type and source_id.

5. ✅✅✅ Opening up a draft (e.g. edit) should build a working form, allow removals, additions, and generally work with everything above!

6. ✅✅✅ Attachments should ... attach :gasp:

7. ✅✅✅ Add Convenience Fee Automatically (if enabled)

8. ✅✅✅ Taxes

9. Apply a coupon

*/

export default class extends Controller {
  static values = {
    modelName: String
  }

  insertNewItem( event ) {
    if (event.keyCode == 13) {
      event.preventDefault()
      const name = $(event.currentTarget).val()
      const row_template = this.buildItemRow(name)
      //debugger;
      $('#line_item_wrapper_div').append(row_template)
      $(event.currentTarget).val('');
    }
  }

  insertItemNotFound() {
    const name = $('#add-item-input').val()
    const row_template = this.buildItemRow(name)
    $('#line_item_wrapper_div').append(row_template)
    $('#add-item-input').val('');
    $('#line-item-search-results').attr('hidden', 'hidden');
  }

  insertSearchItem( event ) {
    // console.log('Clicked!')
    // console.dir(event.params)

    const name = event.params.name
    const price = this.formatPrice(event.params.price)
    const priceCents = event.params.priceCents
    const sourceId = event.params.sourceId
    const sourceType = event.params.sourceType
    const isTaxable = event.params.taxable //a '1' or a '0'

    const row_template = this.buildItemRow(name, price, priceCents, sourceId, sourceType, isTaxable)

    $('#line_item_wrapper_div').append(row_template)
    $(event.currentTarget).val('');
  }

  removeItem( event ) {
    if(!confirm('Are you sure?')) { return; }

    if (!!event.params.id) {
      this.resetRowValues(event)
      const row = $(event.currentTarget).closest('.row')
      row.find('input.hidden-destroy-me').val('1')
      row.hide()
    } else {
      this.resetRowValues(event)
      const row = $(event.currentTarget).closest('.row')
      row.hide()
      setTimeout(function(){
        row.remove()
      }, 1000)
    }
  }

  resetRowValues(event) {
    const row = $(event.currentTarget).closest('.row')
    row.find('input.quantity-cell').val(0)
    row.find('input.price-cell').val(0)
    row.find('input.line-item-total').val(0)
    row.find('input.taxable').prop('checked', false)
  }

  buildItemRow( rowName, price = '1.00', priceCents = 100, sourceId = '', sourceType = '', isTaxable = false ) {
    // This is not the best solution.
    // It can be entirely redone by upgrading to Turbo and using Turbo Frames to reload from a partial.
    const rowId = Date.now().toString()

    return `
      <div class="row py-0 mx-1 line-item-row">
        <div class="col-sm-6 p-2 border border-right-0 border-bottom-0">
          <div class="input-group m-0">
            <div class="align-self-center pr-2 cursor-grab">
              <i class="fas fa-grip-vertical"></i>
            </div>
            <input class="m-0 w-75 form-control"
                   value="${rowName}"
                   name="${this.modelNameValue}[${this.modelNameValue}_line_items_attributes][${rowId}][name]"
            />
          </div>
        </div>
        <div class="col-sm-2 p-2 border border-bottom-0">
          <div class="input-group m-0">
            <input type="number"
                   step="0.001"
                   class="m-0 w-100 form-control quantity-cell"
                   value="1"
                   data-action="change->estimate-calculator#recalculate keyup->estimate-calculator#recalculate"
                   name="${this.modelNameValue}[${this.modelNameValue}_line_items_attributes][${rowId}][quantity]"
            />
           </div>
        </div>
        <div class="col-sm-2 p-2 border border-left-0 border-bottom-0">
          <div class="input-group m-0">
            <div class="input-group-prepend">
              <span class="input-group-text px-1 bg-soft-dark">$</span>
            </div>
            <input type="number"
                   step="0.01"
                   class="form-control m-0 px-1 price-cell"
                   value="${price}" aria-label="Amount (to the nearest dollar)"
                   data-action="change->estimate-calculator#recalculate keyup->estimate-calculator#recalculate"
                   name="${this.modelNameValue}[${this.modelNameValue}_line_items_attributes][${rowId}][price]"
            />
            <input type="hidden"
                   class="hidden-price-cents"
                   value="${priceCents}"
                   name="${this.modelNameValue}[${this.modelNameValue}_line_items_attributes][${rowId}][price_cents]"
            />
          </div>
        </div>
        <div class="col-sm-2 p-2 border border-left-0 border-bottom-0">
          <div class="input-group m-0">
            <div class="input-group-prepend">
              <span class="input-group-text px-1 bg-soft-dark">$</span>
            </div>
            <input readonly="readonly"
                   step="0.01"
                   type="number"
                   class="form-control m-0 px-1 line-item-total bg-transparent"
                   value="${price}"
                   aria-label="Line Item Total"
                   name="${this.modelNameValue}[${this.modelNameValue}_line_items_attributes][${rowId}][total]"

            />
            <input type="hidden"
                   class="hidden-total-cents"
                   value="${priceCents}"
                   name="${this.modelNameValue}[${this.modelNameValue}_line_items_attributes][${rowId}][total_cents]"
            />
            <div class="input-group-append">
              <input type="checkbox"
                     class="taxable m-2"
                     name="${this.modelNameValue}[${this.modelNameValue}_line_items_attributes][${rowId}][taxable]"
                     data-action="change->estimate-calculator#recalculate"
                     ${ isTaxable ? 'checked' : '' }
              />
              <button type="button"
                      class="input-group-text px-1 text-danger delete-me border-0"
                      data-action="click->estimate-line-items#removeItem click->estimate-calculator#recalculate"
              >
                <i class="fas fa-trash-can"></i>
              </button>
            </div>

            <input type="hidden"
                   class="hidden-source-id"
                   value="${sourceId}"
                   name="${this.modelNameValue}[${this.modelNameValue}_line_items_attributes][${rowId}][source_id]"
            />
            <input type="hidden"
                   class="hidden-source-type"
                   value="${sourceType}"
                   name="${this.modelNameValue}[${this.modelNameValue}_line_items_attributes][${rowId}][source_type]"
            />

          </div>
        </div>
      </div>
    `
  }

  formatPrice(num) {
    return new Intl.NumberFormat('en-US', {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
      useGrouping: false
    }).format(num)
  }
}
