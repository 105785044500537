function append(url, address_field_id) {
  Rails.ajax({
    url: url,
    type: 'GET',
    success: function(data) {
      $(address_field_id).attr('disabled', false)
      $(address_field_id).val('')
      if(data.length == 0) {
        clear(address_field_id)
      } else {
        $.each(data, function(_, address) {
          $(address_field_id).append(
            `<option value=${address[1]} data-sales-tax-percentage=${address[2]['data-sales-tax-percentage']}>${address[0]}</option>`
          );
        })
      }
    },
    error: function() {
      console.log('Address not found')
    }
  })
}

function clear(address_field_id) {
  $(address_field_id).attr('disabled', true)
  $(address_field_id).val('')
}

function populate_for_customer_relationship_id(select_target, customer_relationship_id) {
  if (customer_relationship_id) {
    const url = `/service_addresses/${customer_relationship_id}`
    $(select_target).find('option').remove()
    append(url, select_target)
  } else {
    clear(select_target)
  }
  asyncTriggerChangeEvent(select_target);
}

function fireChangeEvent(select_target) {
  return new Promise((resolve) => {
    setTimeout(() => {
      document.querySelector(select_target).dispatchEvent(
        new Event('change', {
          bubbles: true,
          cancelable: true
        })
      );
    }, 250);
  });
}

async function asyncTriggerChangeEvent(select_target) {
  // console.log('calling');
  const result = await fireChangeEvent(select_target);
  return result;
  // console.log(result);
}

$(document).on('turbolinks:load', function() {
  (function($) {
    $("select[data-service-address-fetch]").change(function() {
      const select_target = $(this).data().serviceAddressFetch
      const customer_relationship_id = $(this).val()
      populate_for_customer_relationship_id(select_target, customer_relationship_id)
    })
  })(window.jQuery);
})
