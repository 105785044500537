import { Controller } from 'stimulus'
import $ from 'jquery';
import Select2 from "select2"
import { numberToCurrency } from 'helpers'

export default class extends Controller {

  static targets = ["address", "salesTaxPercent", "salesTaxValue"];

  initialize() {
    // Select2 change event hack, jQuery based `change` events aren't picked up by Stimulus
    // imports MUST be like this,
    // import $ from 'jquery';
    // import Select2 from "select2"
    $(this.addressTarget).select2()
    $(this.addressTarget).on('select2:select', function () {
      let event = new Event('change', { bubbles: true }) // fire a native event
      this.dispatchEvent(event);
    });
  }

  connect() {
    this.updateSubtotal()
    this.updateSalesTax()
    this.updateConvenienceFee()
    this.updateTotal()
  }

  disconnect() {
    this.updateSubtotal()
    this.updateSalesTax()
    this.updateConvenienceFee()
    this.updateTotal()
  }

  recalculate( event ) {
    this.updateLineItemTotals( event )
    this.updateSubtotal()
    this.updateSalesTax()
    this.updateConvenienceFee()
    this.updateTotal()
  }

  updateLineItemTotals( event ) {
    // console.log('Updating Line Item Totals...')
    $('.line-item-row').each(function() {
      const qtyCell = $(event.currentTarget).closest('.row').find('.quantity-cell')
      const priceCell = $(event.currentTarget).closest('.row').find('.price-cell')
      const qtyVal = parseFloat(qtyCell.val())
      const priceVal = parseFloat(priceCell.val())
      const priceCents = parseInt((priceVal * 100).toFixed(2))
      const totalCents = Math.round(priceCents * qtyVal)
      const formattedTotal = numberToCurrency(totalCents / 100.0, { minimumFractionDigits: 2, useGrouping: false } )

      // console.log('*** CALCULATOR LINE ITEMDEBUG VALUES ***')
      // console.log(`qtyVal: ${qtyVal}`)
      // console.log(`priceVal: ${priceVal}`)
      // console.log(`priceCents: ${priceCents}`)
      // console.log(`totalCents: ${totalCents}`)
      // console.log(`formattedRowTotal: ${formattedTotal}`)

      $(event.currentTarget).
        closest('.row').
        find('input.hidden-price-cents').
        val(priceCents)

      $(event.currentTarget).
        closest('.row').
        find('.line-item-total').
        val(formattedTotal)

      $(event.currentTarget).
        closest('.row').
        find('input.hidden-total-cents').
        val(totalCents)
    })
  }

  updateSubtotal() {

    var subTotal = 0;
    $('.hidden-total-cents').each(function() {
      subTotal += parseInt($(this).val())
    })
    const formattedSubTotal = numberToCurrency(subTotal / 100, { style: 'currency', currency: 'USD' })

    // console.log(`Subtotal   : ${subTotal}`)
    // console.log(`# Subtotal : ${formattedSubTotal}`)

    $('#subtotal-cell').html(formattedSubTotal)
    $('#hidden-subtotal-cents').val(subTotal)
  }

  updateSalesTax() {
    let taxableBase = 0
    $('.taxable:checked').each(function() {
      taxableBase += parseInt($(this).closest('.row').find('.hidden-total-cents').val())
    })

    const taxPercent = parseFloat( $(this.addressTarget).find(':selected').data('sales-tax-percentage') || Number(0) )
    const taxCents = Math.round(taxableBase * (taxPercent / 100.0))
    const formattedTax = numberToCurrency(taxCents / 100.0, { style: 'currency', currency: 'USD' } )

    // console.log('*** CALCULATOR SALES TAX DEBUG VALUES ***')
    // console.log(`taxableBase   : ${taxableBase}`)
    // console.log(`taxPercent    : ${taxPercent}`)
    // console.log(`taxMultiplier : ${taxPercent / 100.0}`)
    // console.log(`taxCents      : ${taxCents}`)
    // console.log(`formattedTax  : ${formattedTax}`)

    $('#sales-tax-cell').html(formattedTax)
    $('#hidden-sales-tax-cents').val(taxCents)
    $(this.salesTaxValueTarget).val(taxPercent);
    $(this.salesTaxPercentTarget).html(`(${taxPercent}%)`);
  }

  updateConvenienceFee() {
    //skip if no fee inputs detected
    //skip is selected customer is opted out
    if(
      !$('#hidden-convenience-fee-cents').length ||
      $(".simple_form").find(':selected').data('convenience-fee-opted-out')
    ) {
      $('#convenience-fee-cell').html(numberToCurrency(0, { style: 'currency', currency: 'USD' }))
      $('#hidden-convenience-fee-cents').val(0)
      return
    };

    const feeBase = parseFloat( $('#hidden-convenience-fee-cents').data('convenience-fee-base') )
    const maxCents = parseFloat( $('#hidden-convenience-fee-cents').data('convenience-fee-max-amount-cents') )

    let feeCents =
      (
        parseInt($('#hidden-subtotal-cents').val()) +
        parseInt($('#hidden-sales-tax-cents').val())
      )
        *
      (
        feeBase / 100.0
      )

    // https://www.youtube.com/watch?v=yZjCQ3T5yXo
    // DO NOT CHANGE THIS.  WE MUST ROUND UP.
    let roundedFeeCents = Math.ceil(feeCents)

    // console.log('*** CONVENIENCE FEE DEBUG VALUES ***')
    // console.log(`Fee Percent : ${feeBase}`)
    // console.log(`Max         : ${maxCents}`)
    // console.log(`Calculated  : ${feeCents}`)
    // console.log(`Rounded     : ${roundedFeeCents}`)
    // console.log(`Max Exceeded? ${feeCents > maxCents}`)

    if(feeCents > maxCents) {
      let formattedAmount = numberToCurrency(maxCents / 100, { style: 'currency', currency: 'USD' })
      //console.log(`Formatted  : ${formattedAmount}`)
      $('#convenience-fee-cell').html(formattedAmount)
      $('#hidden-convenience-fee-cents').val(maxCents)
    } else {
      let formattedAmount = numberToCurrency(roundedFeeCents / 100, { style: 'currency', currency: 'USD' })
      //console.log(`Formatted  : ${formattedAmount}`)
      $('#convenience-fee-cell').html(formattedAmount)
      $('#hidden-convenience-fee-cents').val(roundedFeeCents)
    }

  }

  updateTotal() {
    // console.log('Updating Total...')
    // There is no tax or convenience fee, so just copy the Subtotal!
    const subTotal = parseInt($('#hidden-subtotal-cents').val())
    const discount = parseInt($('#hidden-discount-cents').val())
    const fee = parseInt($('#hidden-convenience-fee-cents').val())
    const tax = parseInt($('#hidden-sales-tax-cents').val())
    const newTotal = subTotal + (fee || 0) + tax - (discount || 0)
    const formattedTotal = numberToCurrency(newTotal / 100, { style: 'currency', currency: 'USD' })

    // console.log('*** GRAND TOTAL DEBUG VALUES ***')
    // console.log(`Subtotal : ${subTotal}`)
    // console.log(`Discount : ${discount}`)
    // console.log(`Fee      : ${fee}`)
    // console.log(`Tax      : ${tax}`)
    // console.log(`Total    : ${newTotal}`)
    // console.log($ Total   : ${formattedTotal}`)

    $('#total-cell').html( formattedTotal )

    // Eventually this will need to do more than just copy...
    $('#hidden-total-cents').val( newTotal )

  }
}