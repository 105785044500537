import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    stripeKey: String,
    loaded: Boolean,
    setupIntentUrl: String
  }

  connect() {
    const _this = this;
    const submitBtn = document.querySelector('#payment-submit-btn');
    submitBtn.disabled = true;

    const stripe = Stripe(this.stripeKeyValue);

    (async () => {
      console.log(`Fetching setup intent token from: ${this.setupIntentUrlValue}`)
      const response = await fetch(this.setupIntentUrlValue); //'/settings/payment_methods/setup_intent'
      const { client_secret: clientSecret, redirect_url: redirectUrl } = await response.json();
      // Render the form using the clientSecret
      const options = {
        clientSecret: clientSecret,
      };
      // Set up Stripe.js and Elements to use in checkout form, passing the client secret obtained in step 3
      const elements = stripe.elements(options);
      // Create and mount the Payment Element
      const paymentElement = elements.create('payment');
      paymentElement.mount('#payment-element');

      submitBtn.disabled = false;

      paymentElement.on('ready', function(event) {
        // Handle ready event
        _this.loadedValue = true;
      });

      const form = document.getElementById('payment-form');
      form.addEventListener('submit', async (event) => {
        event.preventDefault();

        const { error } = await stripe.confirmSetup({
          //`Elements` instance that was used to create the Payment Element
          elements,
          confirmParams: {
            return_url: redirectUrl,
          },
          redirect: 'always'
        });

        if (error) {
          // This point will only be reached if there is an immediate error when
          // confirming the payment. Show error to your customer (for example, payment
          // details incomplete)
          const messageContainer = document.querySelector('#error-message');
          messageContainer.textContent = error.message;
          setTimeout(function () {
            submitBtn.disabled = false;
          }, 1000)
        } else {
          // Your customer will be redirected to your `return_url`. For some payment
          // methods like iDEAL, your customer will be redirected to an intermediate
          // site first to authorize the payment, then redirected to the `return_url`.
        }
      });
    })();


  }
}
