import { Controller } from "stimulus";
import { Loader } from "@googlemaps/js-api-loader";
import { mapLoader } from "../helpers/mapLoader";

export default class extends Controller {
  static values = {
    googleMapKey: String
  }
  static targets = ["dynamic-map"];

  connect() {
    const loader = new Loader({
      apiKey: this.googleMapKeyValue,
      version: "weekly",
    });

    loader.load().then(() => {
      mapLoader();
    });
  }
}
