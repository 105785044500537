import { Controller } from "stimulus"
import "jquery"

export default class extends Controller {

  handleClick( event ) {
    const target = $(event.target)
    const isChecked = target.is(":checked")

    const options = {
      headers: {
        "X-CSRF-Token": document.querySelector("meta[name='csrf-token']").content
      },
      method: "PUT"
    };

    fetch(`/customer_app/payment_methods/toggle_autopay.json?autopay_enabled=${ Number(isChecked) }`, options)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Unable to Toggle Autopay.  Ensure there is at least one credit card on file.`);
        }
        return response.json();
      })
      .then((data) => {
        console.log(`Autopay Changed: ${data.autopay_enabled}`)
        target.prop('checked', data.autopay_enabled);
      })
      .catch((err) => {
        target.prop('checked', !isChecked);
        console.dir(err);
      });
  }
}
