import { Controller } from "stimulus"
import "jquery"

export default class extends Controller {
  //"data-action": "click->dashboard-maps#reloadLocations"
  toggleClass( event ) {
    event.preventDefault();
    //debugger;
    //var target = jQuery(event.target.closest('p.open-dropdown'))
    // # TODO - move me to a stimulus controller
    $($(event.currentTarget).attr("data-target")).toggleClass($(event.currentTarget).attr("data-toggleClass"));
  }
}
