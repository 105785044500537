import { Controller } from 'stimulus';
import Inputmask from 'inputmask';

export default class extends Controller {
  connect() {
    let submitButton = document.getElementById('new_user_registration_button');
    let phoneInput = document.getElementById('user_company_attributes_phone');
    const inputMask = new Inputmask({
      mask: '(999)999-9999',
      placeholder: ' ',
      showMaskOnHover: false,
      showMaskOnFocus: false,
    });
    inputMask.mask(phoneInput);

    phoneInput.addEventListener('input', () => {
      this.validatePhone(phoneInput.value);
    });

    // Submit form on valid phone number
    phoneInput.form.addEventListener('submit', (event) => {
      if (!this.validatePhone(phoneInput.value)) {
        event.preventDefault();
        // I've to remove this attribute, to prevent Rails disable the submit button
        // after run event.preventDefault() (from Rails 5 +), disable submits by default
        submitButton.removeAttribute('data-disable-with');
        this.showErrorMessage(phoneInput);
      }
    });
  }

  validatePhone(phoneNumber) {
    const phoneRegex = /^\(\d{3}\)\d{3}-\d{4}$/;
    return phoneRegex.test(phoneNumber);
  }

  showErrorMessage(phoneInput) {
    const errorElement = document.getElementById('phone-error-message');
    if (errorElement) {
      errorElement.textContent =
        'Please enter a valid phone number in the format (999)999-9999.';
    } else {
      const errorMessage = document.createElement('p');
      errorMessage.id = 'phone-error-message';
      errorMessage.textContent =
        'Please enter a valid phone number in the format (999)999-9999.';
      errorMessage.classList.add('error-message');
      phoneInput.parentNode.insertBefore(errorMessage, phoneInput.nextSibling);
    }
  }
}
