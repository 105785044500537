$(document).on('turbolinks:load', function() {

  $(function() {
    $('[data-behavior="alert-dimissible-container"] .alert').on('closed.bs.alert', function() {
      $('[data-behavior="alert-dimissible-container"]').remove()
    })
  })

  $("#add_field").on("click", function() {
    let content = $('.items-template').html()
    $('.forms-wrapper').append(content)
  });

  $(document).on('click', '#remove_field', function(event) {
    let wrapper = $(event.target).closest('.nested-fields');
    wrapper.remove();
  });

  let el = $('.service-items')[0];
  if(el) {
    Sortable.create(el, {
      onEnd: function(event){
        let url = event.item.dataset.url
        let data = new FormData()
        data.append("position", event.newIndex + 1)
        Rails.ajax({
          url: url,
          type: 'PATCH',
          data: data
        })
      }
    });
  }
})


