import { Controller } from "stimulus";
import moment from "moment";
import * as mobiscroll from "../custom/mobiscroll.js";
export default class extends Controller {
  connect() {
    this.renderCalendar();
  }

  async renderCalendar() {
    const calendarEl = document.getElementById("mobiscroll-events-calendar");
    const technicianId = calendarEl.getAttribute("data-technician-id");
    const selectedRoute = document.getElementById("route_id");
    let resources;

    if(technicianId && selectedRoute === null) {
      resources = JSON.parse(calendarEl.getAttribute("data-resource"));
    } else
    {
      let selectedRouteId = selectedRoute.value;
      resources = await fetch(`/jobs/calendar_routes.json?route_id=${selectedRouteId}`).then((response) => {
      return response.json();
    }).then((data) => {
      this.initializeCalendar(data, technicianId)
    }).catch((error) => {
      console.error('Error:', error);
    });
    }
  }


  initializeCalendar(resources, technicianId) {
    const daysInWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const calendarEl = document.getElementById("mobiscroll-events-calendar");
    const eventsPath = calendarEl.getAttribute("data-events-path");
    var calendar = null;

    // mobiscroll initialization and events
    mobiscroll.setOptions({
      theme: "ios",
      themeVariant: "light",
    });

    if (resources.length > 0) {
      calendar = mobiscroll.eventcalendar("#mobiscroll-events-calendar", {
        view: {
          timeline: {
            type: "week",
            eventList: true,
            startDay: 1,
            endDay: 5,
            weekNumbers: true,
            eventList: true,
            startTime: "00:00",
            endTime: "00:00",
          },
        },
        resources: resources,
        dragToMove: true,
        dragTimeresourcesStep: 15,
        onEventClick: function (data) {
          if (
            confirm(`
            Navigating To:    ${data.event.street}
            Customer Name:    ${data.event.job_title}
            Address:    ${data.event.address}
            Route:    ${data.event.route}
            Technician(s):    ${data.event.technicians}
            Service(s):   ${data.event.services}
          `)
          )
            window.location.href = `/jobs/${data.event.id}`;
        },
        onInit: function (event, args) {
          fetchEvents(args._firstDay, args._lastDay, eventsPath);
        },
        onPageChange: function (args) {
          fetchEvents(args.firstDay, args.lastDay, eventsPath);
        },
        onEventUpdate: function (args, inst, i) {
          let todaysDate = moment().format();
          let updatedDate = moment(args.event.start);

          if (updatedDate.isBefore(todaysDate, "day")) {
            mobiscroll.toast({
              message: "Cannot reschedule to a date in the past",
              color: "danger",
            });
            inst.updateEvent(args.oldEvent);
            return false;
          }

          let data = {
            job: {
              scheduled_on: updatedDate.format(),
              route_id: args.event.resource,
            },
          };
          let options = {
            headers: {
              accept: "application/json",
              "Content-Type": "application/json",
            },
            method: "PUT",
            body: JSON.stringify(data),
          };
          fetch(`/jobs/${args.event.id}`, options)
            .then((response) => {
              if (!response.ok) {
                throw new Error("HTTP status", response);
              }
              return response.json();
            })
            .then((response) => {
              inst.updateEvent({ ...args.event, color: response.color });
              mobiscroll.toast({
                message: response.message,
                color: "success",
              });
            })
            .catch((err) => {
              mobiscroll.toast({ message: "Unsuccessful", color: "danger" });
              // if there is an error we want to revert the changes done by the calendar
              inst.updateEvent(args.oldEvent);
            });
        },
        renderHeader: function () {
          return `
              <div mbsc-calendar-nav class="md-work-week-nav"></div>
              <div class="md-work-week-picker">
                <div>
                  <div class="d-flex justify-content-center mbsc-custom-type-selector py-1">
                    <label id="work-week-btn">Work week<input mbsc-segmented type="radio" name="view" value="workweek" class="event-listing-view-change" checked></label>
                    <label id="week-btn">Week<input mbsc-segmented type="radio" name="view" value="week" class="event-listing-view-change"></label>
                    <label id="month-btn">Month<input mbsc-segmented id="month-radio" type="radio" name="view" value="month" class="event-listing-view-change"></label>
                  </div>
                  <div class="mbsc-custom-day-selector py-1">
                    ${daysInWeek
                      .map(
                        (name, val) =>
                          `<label class="event-listing-day-change">
                            ${name}
                            <input mbsc-segmented type="radio" name="switching-timeline-view" value="${val}" class="md-timeline-view-change"></label>`
                      )
                      .join("")}
                  </div>
                </div>
              </div>
              <div mbsc-calendar-prev class="md-work-week-prev"></div>
              <div mbsc-calendar-today class="md-work-week-today"></div>
              <div mbsc-calendar-next class="md-work-week-next"></div>
                  `;
        },
        renderResource: function (resource) {
          return `<div class="md-work-week-cont">
                    <div class="md-work-week-name">
                      ${resource.name}
                      </div>
                      <div class="md-work-week-title">
                        ${resource.technicians
                          .map(
                            (technician) =>
                              `
                                <span data-toggle="tooltip" data-placement="bottom" title="${technician.name}">
                                  <img class="calendar-technician-avatar" src="${technician.img}"/>
                                </span>
                              `
                          )
                          .join("")}
                      </div>
                    </div>`;
        },
        renderScheduleEventContent: function (data) {
          return (
            `<span class="mr-1" style="font-size: 16px">${getEventIcon(
              data.original.event_icon
            )}</span>` + data.original.job_title
          );
        },
      });
    } else {
      document.getElementById("mobiscroll-events-calendar").innerHTML =
        "<h3>No Routes</h3>";
    }

    // functions and event handlers

    function fetchEvents(startDate, endDate, path) {
      const params = `?start=${startDate}&end=${endDate}${
        technicianId ? `&technician_id=${technicianId}` : ""
      }`;
      mobiscroll.util.http.getJson(
        path + params,
        function (events) {
          calendar.setEvents(events);
        },
        "json"
      );
    }

    function getEventIcon(event_icon) {
      switch (event_icon) {
        case "started":
          return "&#x1F3E0";
        case "cancelled":
          return "&#x274C";
        case "completed":
          return "&#x2705";
        case "paid":
          return "&#x2705";
        default:
          return "";
      }
    }

    function unSelectDay(targetElement) {
      // day tab parent which will have selected calls
      const parentNode = targetElement.parentNode;
      // sibling of input
      const selectBoxParent = parentNode.querySelector(
        ".mbsc-segmented-selectbox"
      );
      if (selectBoxParent) {
        selectBoxParent.classList.remove("mbsc-selected");
      }
      // child of that sibling
      const selectBoxChild = selectBoxParent.querySelector(
        ".mbsc-segmented-selectbox-inner-visible"
      );
      if (selectBoxChild) {
        selectBoxChild.classList.remove(
          "mbsc-segmented-selectbox-inner-visible"
        );
        selectBoxChild.classList.remove("mbsc-selected");
      }
      //input element itslef
      targetElement.classList.remove("mbsc-selected");
      targetElement.checked = false;
    }

    // event handler for refresh events

    document
      .querySelector(".mobiscroll-refresh-events")
      .addEventListener("click", function () {
        fetchEvents(calendar._firstDay, calendar._lastDay, eventsPath);
      });

    // event handler for the days in week tabs
    document
      .querySelectorAll(".event-listing-day-change")
      .forEach(function (elm) {
        elm.addEventListener("change", function (ev) {
          const targetElement = document.querySelector(
            "div.mbsc-custom-type-selector input[value]:checked"
          );
          let viewType = "month";
          if (targetElement) {
            if (targetElement.value) {
              viewType = targetElement.value;
            }
          }
          calendar.setOptions({
            view: {
              timeline: {
                type: viewType,
                startDay: ev.target.value,
                endDay: ev.target.value,
                startTime: "00:00",
                endTime: "00:00",
                weekNumbers: true,
                eventList: true,
              },
            },
          });
        });
      });

    // event handler for the month/week/workweek tabs
    document
      .querySelectorAll(".event-listing-view-change")
      .forEach(function (elm) {
        elm.addEventListener("change", function (ev) {
          const targetElement = document.querySelector(
            ".event-listing-day-change input[value]:checked"
          );
          // these determines the days in week that show up in the table
          let activeDayStart = 1;
          let activeDayEnd = 5;
          if (targetElement) {
            // if no day is selected then show all days by default
            if (targetElement.value && targetElement.value != -1) {
              activeDayStart = targetElement.value;
              activeDayEnd = targetElement.value;
              unSelectDay(targetElement);
            }
          }
          switch (ev.target.value) {
            case "workweek":
              calendar.setOptions({
                view: {
                  timeline: {
                    type: "week",
                    eventList: true,
                    startDay: 1,
                    endDay: 5,
                    weekNumbers: true,
                    eventList: true,
                  },
                },
              });
              break;
            case "week":
              calendar.setOptions({
                view: {
                  timeline: {
                    type: "week",
                    eventList: true,
                    startDay: 0,
                    endDay: 6,
                    weekNumbers: true,
                    eventList: true,
                  },
                },
              });
              break;
            case "month":
              calendar.setOptions({
                view: {
                  timeline: {
                    type: "month",
                    startDay: activeDayStart,
                    endDay: activeDayEnd,
                    startTime: "00:00",
                    endTime: "00:00",
                    weekNumbers: true,
                    eventList: true,
                  },
                },
              });
              break;
          }
        });
      });
  }
}
