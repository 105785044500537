import { Controller } from "stimulus"
import "jquery"

export default class extends Controller {
  //"data-action": "click->dashboard-maps#reloadLocations"
  // $(document).on("click", " .admin-close-sidebar ,.sidebar-backdrop"
  handleClick( event ) {
    event.preventDefault();
    $('body').removeClass("sidebar-open");
  }
}
