import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "billingAddress",
    "billingAddress2",
    "billingCity",
    "billingState",
    "billingPostalCode"
  ]

  copyShippingToBilling(e) {
    let parent = $(e.target).parents('.nested-fields')
    let shippingAddress = parent.find('.service_address_input').val();
    let shippingAddress2 = parent.find('.service_address_2_input').val();
    let shippingCity = parent.find('.service_address_city').val();
    let shippingPostalCode = parent.find('.service_address_postal_code').val();
    let shippingState = parent.find('.service_address_state_province_abbr').val();

    this.billingAddressTarget.value = shippingAddress;
    this.billingAddress2Target.value = shippingAddress2;
    this.billingCityTarget.value = shippingCity;
    this.billingPostalCodeTarget.value = shippingPostalCode;

    this.billingStateTarget.value = shippingState;
    // trigger the change event manually so select2 can update.
    this.billingStateTarget.dispatchEvent(new Event("change"));
  }
}
