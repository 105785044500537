import { Controller } from "stimulus"

export default class extends Controller {

  selectionHandler(e) {
    e.preventDefault()
    $('.batchaction-item').hide()
    $(e.target.parentNode).find('.batchaction-item').show()
    $("#selected_batch_action").val($(e.target).attr('for'))
  }

  bulkUpdate(e) {
    let has_error = false
    let model = $("#selected_ids").attr('model')
    if ($("#selected_ids").val() == "") {
      alert(`Please select at least one ${model} from the list for bulk update`)
      has_error = true
    } else {
      let selected_batch_action = $("#selected_batch_action").val()
      if (selected_batch_action == "") {
        alert("Please select a Batch action for update")
        has_error = true
      } else {
        if ($(`#${selected_batch_action}`).val() == "") {
          alert("Please select a value for this Batch action")
          has_error = true
        }        
      }
    }
    if (has_error) {
      e.preventDefault()
      e.stopPropagation()
    }
  }
 
}
