import { Controller } from "stimulus";
import { Loader } from "@googlemaps/js-api-loader";

export default class extends Controller {
  static values = {
    googleMapKey: String
  }
  static targets = ["dynamic-map"];

  connect() {
    this.loader = new Loader({
      apiKey: this.googleMapKeyValue,
      version: "weekly",
    });
    this.setMap = {};
    this.infoWindow = {};
    this.currentMarkers = [];
    this.loadMap();
  }

  reloadLocations() {
    fetch("/dashboards/technician_locations", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((res) => {
        this.locationMarkerMapper(JSON.parse(res.locations));
      });
  }

  loadMap() {
    this.loader.load().then(() => {
      let map_element = document.getElementById("technicians-map");
      if (map_element) {
        let locations = JSON.parse(
          document.getElementById("technicians-map").dataset.locations
        );
        let myStyles = [
          {
            featureType: "poi",
            elementType: "labels",
            stylers: [{ visibility: "off" }],
          },
        ];
        this.setMap = new google.maps.Map(
          document.getElementById("technicians-map"),
          {
            center: {
              lat: parseFloat(locations["center"][0]),
              lng: parseFloat(locations["center"][1]),
            },
            zoom: 12.1,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            styles: myStyles,
          }
        );

        let map = this.setMap;

        // dynamic zoom level
        // if (locations['addresses'].length > 0) {
        //   let bounds = generateBounds(locations);
        //   map.fitBounds(bounds);
        // }
        // generate company location marker
        const position = new google.maps.LatLng(
          parseFloat(locations["center"][0]),
          parseFloat(locations["center"][1])
        );
        let svgMarker = {
          path: "M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z",
          fillColor: "#2D5DFF",
          fillOpacity: 1,
          strokeWeight: 0,
          rotation: 0,
          scale: 1.8,
          anchor: new google.maps.Point(12, 24),
        };
        const marker = new google.maps.Marker({
          position,
          map,
          icon: svgMarker,
        });
        const label =
          '<div id="content">' +
          `<h5 id="firstHeading" class="firstHeading">${locations["company_name"]}</h1>` +
          "</div>";
        marker.addListener("click", () => {
          infoWindow.setContent(label);
          infoWindow.open(map, marker);
        });

        this.infoWindow = new google.maps.InfoWindow({
          content: "",
          disableAutoPan: true,
        });

        const infoWindow = this.infoWindow;

        // Add some markers to the map.
        this.locationMarkerMapper(locations);
      }
    });
  }

  locationMarkerMapper(locations) {
    let map = this.setMap;
    const infoWindow = this.infoWindow;
    if (this.currentMarkers.length > 0) {
      this.currentMarkers.forEach((marker) => marker.setMap(null));
    }
    locations["addresses"].map((location) => {
      const label =
        '<div id="content">' +
        '<div id="siteNotice">' +
        "</div>" +
        `<h5 id="firstHeading" class="firstHeading">${location.title}</h1>` +
        '<div id="bodyContent">' +
        `${location.phone_number} </br>` +
        "</div>" +
        "</div>";
      const position = this.parseCoordinates(location);
      const marker = new google.maps.Marker({
        position,
        map,
        icon: null,
      });

      marker.addListener("click", () => {
        infoWindow.setContent(label);
        infoWindow.open(map, marker);
      });
      this.currentMarkers.push(marker);
      return marker;
    });
  }

  parseCoordinates(location) {
    return {
      lat: parseFloat(location.latitude),
      lng: parseFloat(location.longitude),
    };
  }
}
